import React from "react";
import { Box, Container, Link } from "@chakra-ui/react";
import Heading from "../component/Heading/Heading";
import Text from "../component/SubHeading/SubHeading";

const Faqs = () => {
  return (
    <>
      <Box
        backgroundImage={{
          sm: "url(./images/landingpage1.png)",
          md: "url(./images/landingpage1.png)",
        }}
        backgroundColor="black"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        height={{ sm: "40vh", md: "355px" }}
        backgroundSize="cover"
      >
        <Container
          maxW="container.2xl"
          backgroundColor="rgba(0,0,0,0.5)"
          p={{ sm: "0 20px", md: "0 70px", lg: "0 130px" }}
          justifyContent="center"
          display="flex"
          height="100%"
          alignItems="end"
        >
          <Box
            paddingBottom="30px"
            textTransform="uppercase"
            alignItems="center"
            display="flex"
            flexDirection="column"
            className="wow animate fadeInUp"
          >
            <Heading
              title="FAQs"
              color="#fff"
              lineHeight="1"
              padding="0px 0px 5px"
              variant="largheading"
              textAlign="center"
            />
          </Box>
        </Container>
      </Box>
      <Container
        maxW="container.xl"
        paddingTop={{ sm: "30px", md: "25px", lg: "55px" }}
      >
        <Box paddingBottom={{ sm: "30px", md: "25px", lg: "55px" }}>
          <Heading
            title="GENERAL TERMS"
            variant="large"
            lineHeight="0.9"
            padding="15px 0"
            textAlign="center"
          />
          <Text
            marginBottom="20px"
            title="This Site is owned and operated by CrossFit Ireland Ltd."
          />
          <Text
            marginBottom="20px"
            title="These terms and conditions include general terms and conditions for use of this site, terms and conditions relating to the services provided by CrossFit Ireland Ltd on the site and in our studio."
          />
          <Text title="Please read these terms and conditions carefully. Your use of the site, the purchase of any Products or Services on this site or in a studio will be subject to these terms and conditions. And you now hereby agree to our terms and conditions." />
        </Box>
        <Box paddingBottom={{ sm: "30px", md: "25px", lg: "55px" }}>
          <Box marginBottom="30px">
            <Heading
              title="FAQs"
              variant="large"
              lineHeight="0.9"
              padding="15px 0"
              textAlign="center"
              color="Red"
            />
            <Heading
              title="What are your hours of operation?"
              variant="small"
            />
            <Text
              marginBottom="10px"
              title="WML: Monday-Thursday 6am-9pm | Friday 6am-8pm | Saturday-Sunday 8am-2pm"
            />
            <Text title="LNX: Monday-Friday 6am-12pm, 4pm-8pm | Saturday-Sunday 9am-12pm" />
          </Box>
          <Box marginBottom="30px">
            <Heading
              title="Do you have a place I can securely leave my stuff while I work out?"
              variant="small"
            />
            <Text title="We have lockers in our men's and women's changing rooms where you can leave your belongings. Each locker allows you to create a single-use code while you're in the facility." />
          </Box>
          <Box>
            <Heading title="Do you have showers?" variant="small" />
            <Text
              title="Yes, we have showers in both men’s and women’s changing rooms. We also provide shampoo, conditioner, body wash, hair dryers & straighteners (plus a few extra goodies you might need to replenish after your sweaty session with us!)
"
            />
          </Box>
        </Box>
        <Box paddingBottom={{ sm: "30px", md: "25px", lg: "55px" }}>
          <Box marginBottom="30px">
            <Heading
              title="CROSSFIT"
              variant="large"
              lineHeight="0.9"
              padding="15px 0"
              textAlign="center"
              color="Red"
            />
            <Text
              textAlign="center"
              title={
                <Box>
                  For all CrossFit enquiries, please contact us at{" "}
                  <Link
                    href="mailto:crossfit@perpetua.ie"
                    textDecoration="underline"
                  >
                    crossfit@perpetua.ie!
                  </Link>
                </Box>
              }
            ></Text>
          </Box>
        </Box>
        <Box paddingBottom={{ sm: "30px", md: "25px", lg: "55px" }}>
          <Box marginBottom="30px">
            <Heading
              title="JustGYM"
              variant="large"
              lineHeight="0.9"
              padding="15px 0"
              textAlign="center"
              color="Red"
            />
            <Box marginBottom="30px">
              <Heading
                title="If I have a studio pack can I also use the JustGYM area?"
                variant="small"
              />
              <Text title="Studio packs are for use in our studios only. But on a JustGYM+ membership, you also receive 8 credits per month that you can use!" />
            </Box>
            <Box marginBottom="30px">
              <Heading
                title="How long is a JustGYM membership good for?"
                variant="small"
              />
              <Text title="Our JustGYM contract is 3 months in duration; after 3 months is up, your membership is a rolling month-to-month billing cycle. After your 3 months, you can cancel at any time with 30 days notice to our membership team." />
            </Box>
            <Box marginBottom="30px">
              <Heading
                title="What do I get for my monthly membership fee?"
                variant="small"
              />
              <Text title="You get access to our JustGYM space anytime Perpetua is open! Additionally, new JustGYM members get a complimentary consultation with a trainer who will help you put together a program." />
            </Box>
            <Box>
              <Heading
                title="How do I become a JustGYM member?"
                variant="small"
              />
              <Text title="Simply stop by the front desk to get the ball rolling on becoming a JustGYM member!" />
            </Box>
          </Box>
        </Box>
        <Box paddingBottom={{ sm: "30px", md: "25px", lg: "55px" }}>
          <Box marginBottom="30px">
            <Heading
              title="STUDIO CLASSES"
              variant="large"
              lineHeight="0.9"
              padding="15px 0"
              textAlign="center"
              color="Red"
            />
            <Box marginBottom="30px">
              <Heading
                title="How do I purchase studio credits?"
                variant="small"
              />
              <Text title="You can purchase class credits online through your account on perpetua.ie, or through the Perpetua app: Android or iOS" />
            </Box>
            <Box marginBottom="30px">
              <Heading
                title="What can I use my studio credits for?"
                variant="small"
              />
              <Text
                marginBottom="10px"
                title="You can use credits to book into our RIDE or SWEAT studios!"
              />
              <Text
                marginBottom="10px"
                title={
                  <Box>
                    Our RIDE studio features the following:
                    <br />
                    RIDE45 // RIDE60 <br />
                    RHYTHM RIDE <br />
                    PERFORMANCE RIDE
                  </Box>
                }
              />
              <Text
                marginBottom="10px"
                title={
                  <Box>
                    Our SWEAT studio features the following:
                    <br />
                    Tread & Shred
                  </Box>
                }
              />
            </Box>
            <Box marginBottom="30px">
              <Heading
                title="Do you provide cycling shoes for RIDE classes?"
                variant="small"
              />
              <Text title="We do not provide shoes however our bikes are compatible with SPD clips, so you’re welcome to bring your own if you have them! Our bikes do have hybrid pedals so you can comfortably and safely wear your regular trainers as well." />
            </Box>
            <Box marginBottom="30px">
              <Heading
                title="What is the cancellation policy for studio bookings?"
                variant="small"
              />
              <Text
                marginBottom="10px"
                title="Our cancellation window is 8 hours before the booking starts. If you cancel at least 8 hours in advance, it is considered an “early cancel.” This means that you do not lose a credit. Any cancellation within 8 hours is considered a “late cancel,” and in this instance your account is charged as if you had attended. We have this policy in place to ensure that all guests are able to book into their favourite movement offerings, so make sure to book with intention!"
              />
              <Text title="If you are late and unable to make the class, unfortunately we cannot return or refund the credit." />
            </Box>
            <Box marginBottom="30px">
              <Heading
                title="How do I check in for my booking?"
                variant="small"
              />
              <Text title="Simply stop by the front desk and let us know your name and what you’re here for!" />
            </Box>
            <Box marginBottom="30px">
              <Heading
                title="What happens if I’m late to a class??"
                variant="small"
              />
              <Text
                marginBottom="10px"
                title={
                  <Box>
                    We operate on a <strong>CLOSED DOOR POLICY;</strong> for
                    health and safety reasons, once the session has begun and
                    the coach has closed the door, we are unable to allow entry
                    to anyone that has arrived late. As this is in the best
                    interest of both your experience and our team, we ask that
                    this is respected by all guests.
                  </Box>
                }
              />
              <Text title="If you’re arrived late for a class, unfortunately we cannot return or refund the credit." />
            </Box>
            <Box marginBottom="30px">
              <Heading
                title="When does my studio pack expire?"
                variant="small"
              />
              <Text
                marginBottom="10px"
                title={
                  <Box>
                    Each pack has an expiry date as follows:
                    <br />
                    1 credit – 30 days expiry
                    <br />
                    3 pack – 30 days expiry
                    <br />
                    5 pack – 45 days expiry
                    <br />
                    10 pack – 3 months expiry
                    <br />
                    15 pack – 6 months expiry
                    <br />
                    20 pack – 9 months expiry
                    <br />
                    30 pack – 12 months expiry
                  </Box>
                }
              />
              <Text
                marginBottom="10px"
                title="These expiry dates are based off the first booking using those credits. For example, if you purchase a 3-pack and book into  March 13th, your pack will expire on April 13th."
              />
              <Heading
                title="**Any of our Promo Credit packs activate from the DATE OF PURCHASE **"
                variant="extrasmall"
                color="grey"
              />
            </Box>
          </Box>
        </Box>
        <Box paddingBottom={{ sm: "30px", md: "25px", lg: "55px" }}>
          <Box marginBottom="30px">
            <Heading
              title="STUDIO MEMBERSHIP T&C"
              variant="large"
              lineHeight="0.9"
              padding="15px 0"
              textAlign="center"
              color="Red"
            />
            <Text
              marginBottom="20px"
              title="We offer flexible monthly memberships that can be used in our studios: STUDIO8 (8 credits/month), STUDIO12 (12 credits/month), STUDIO16 (16 credits/month)."
            />
            <Text
              marginBottom="20px"
              title="Studio members may avail of special pricing on additional credits, should they wish to purchase them. Pricing is as follows:"
            />
            <Text
              marginBottom="20px"
              title="STUDIO8: purchase additional credits for €21"
            />
            <Text
              marginBottom="20px"
              title="STUDIO12: purchase additional credits for €19"
            />
            <Text
              marginBottom="20px"
              title="STUDIO16: purchase additional credits for €17"
            />
            <Text
              marginBottom="20px"
              title="These single credits have a one-month expiry. Single credits cannot be frozen, transferred, or shared."
            />
            <Text
              marginBottom="20px"
              title="Studio memberships operate on a contract basis. The agreement runs for a minimum of 3 months from the start date. It will automatically be renewed for subsequent months, unless cancelled with 30-days notice by the client."
            />
            <Text
              marginBottom="20px"
              title="All studio members will receive membership status and the benefits therein. When this agreement is terminated, membership status and all member benefits end."
            />
            <Text marginBottom="20px" title="Terms and Conditions:" />
            <Text
              marginBottom="20px"
              title={
                <Box>
                  -Credits can not be frozen or extended
                  <br />
                  -Unused credits cannot be carried over to following month
                  <br />
                  -Credits are valid at all studios
                  <br />
                  -Membership applies to studio classes only
                  <br />
                  -Membership blocks, status or benefits can not be shared
                  <br />
                  -Memberships can not be refunded
                </Box>
              }
            />
            <Text title="-Payment will be taken every 30 days from the initial purchase date via credit card on client’s account" />
          </Box>
        </Box>
        <Box paddingBottom={{ sm: "30px", md: "25px", lg: "55px" }}>
          <Box marginBottom="30px">
            <Heading
              title="LIABILITY STATEMENT"
              variant="large"
              lineHeight="0.9"
              padding="15px 0"
              textAlign="center"
              color="Red"
            />
            <Text
              marginBottom="20px"
              title="In consideration of CrossFit Ireland Ltd allowing you to participate, you acknowledge, understand and are aware that:"
            />
            <Text
              marginBottom="20px"
              title="You have voluntarily chosen to participate in training activities provided by CrossFit Ireland Ltd. You understand that the training may involve weightlifting, gymnastic movements, strenuous bodyweight exercises and other high exertion activities, and that you are not obligated to perform nor participate in any activity that you do not wish to do, and that it is your right to refuse such participation at any time during your training sessions."
            />
            <Text
              marginBottom="20px"
              title="Use of picture(s)/film/likeness: You agree to allow CrossFit Ireland Ltd, its agents, officers, principals, employees and volunteers to use picture(s) film and/or likeness of me for advertising purposes. In the event you choose not to allow the use of the same for said purpose, you agree that you must inform CrossFit Ireland Ltd, of this in writing."
            />
            <Text
              marginBottom="20px"
              title="If you are a visitor dropping into a CrossFit class , you confirm you have 6 months CrossFit experience. If the coach deems you are unable to train due to lack of experience you are not entitled to a refund. If you do not turn up on the day you are not eligible for a refund."
            />
            <Text
              marginBottom="20px"
              title="It is also vital that you supply us with correct information about yourself. We cannot be liable for any incorrect information supplied by you to us. We try to make sure that all information contained on this site (and provided by us to you as part of any Services or Products) is correct, but, subject to the paragraph below, we do not accept any liability for any error or omission and exclude all liability for any action you (your legal representatives, heirs) may take or loss or injury you may suffer (direct or indirect including loss of pay, profit, opportunity or time, pain and suffering, any indirect, consequential or special loss, however arising) as a result of relying on any information on this site or provided through any Service supplied by us to you."
            />

            <Heading
              title="Don’t see your question?"
              variant="medium"
              lineHeight="0.9"
              padding="15px 0"
              textAlign="center"
            />
            <Text
              textAlign="center"
              title={
                <Box>
                  Contact us at{" "}
                  <Link
                    href="mailto:hello@perpetua.ie"
                    textDecoration="underline"
                    fontWeight="bold"
                  >
                    hello@perpetua.ie
                  </Link>
                </Box>
              }
            ></Text>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Faqs;
